<template>
	<DefaultTemplate>
		<el-form label-position="top" :model="form" :rules="rules" ref="ruleForm" label-width="120px" >
			<el-form-item label="เลือกจำนวนผู้เล่น" prop="amount" class="rounded">
				<el-select v-model="form.amount" placeholder="Max Players" class="w-100">
				    <el-option
				      v-for="item in options"
				      :key="item.value"
				      :label="item.label"
				      :value="item.value">
				    </el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<el-button  class="mg-y-5 w-100" type="primary" @click="submit()" :loading="loadingNext" round>Create</el-button>
		<!-- <button @click="submit()" class="mg-y-5 w-100 btn-primary" type="submit" round>Create</button> -->
	</DefaultTemplate>
</template>
<script>

import { HTTP } from'@/service/axios'
import { alertFailError } from'@/service/alertMsg'
import DefaultTemplate from '@/template/DefaultTemplate'
export default {
	components:{
		DefaultTemplate
	},
	computed:{
		user(){
			return this.$store.state.user
		}
	},
	data(){
		return{
			loadingNext:false,
			form:{
				amount:''
			},
			rules: {
				amount: [
					{ required: true, message: 'Please select player', trigger: 'blur' },
				],
        	},
			options:[
				{value:2,label:'2'},
				{value:3,label:'3'},
				{value:4,label:'4'},
				{value:5,label:'5'},
				{value:6,label:'6'},
				{value:7,label:'7'},
				{value:8,label:'8'},
			]
		}
	},
	mounted(){
		if(typeof this.user == 'undefined' && this.user.userLevel != 80 && this.user == null){
			this.$router.push('/sign-in').catch(err => {})
		}
	},
	methods:{

		submit(){
			this.loadingNext = true
			this.$refs['ruleForm'].validate((valid) => {
          		if (valid) {
          			let create = {
          				amountUser:this.form.amount,
          				mode:'workShop'
          			}
          			HTTP.post('/room',create).then(res=>{
         
          				let demand = {
	          				roomId:res.data.obj._id,
							amount:this.form.amount,
	          			}
          				if(res.data.success){
          					HTTP.post('/demand',demand).then(respone=>{
          	
          						if(respone.status == 200){
          							let data = {
          								groups:[],
          								amfreland:respone.data.amfreland,
          								belovia:respone.data.amfreland,
		          						roomNumber:res.data.obj.roomNumber,
		          						maxPlayer:this.form.amount,
		          						_id:res.data.obj._id,
		          						currentRound:1,
		          						current:""
	          						}
	          						this.$store.dispatch('updateCurrent',data)
		          					this.$store.commit('SET_ROOM',data)
		          					this.$router.push('/lobby-admin').catch(err => {})
	          					}
          					}).catch(e=>{
		          				console.log('/demand error',e)
		          				this.alertFailError()
		          			})	
          				}
          			}).finally(()=>{
          				this.loadingNext = false
          			}).catch(e=>{
          				console.log('/user error',e)
          				this.alertFailError()
          			})
				}else{
					this.loadingNext = false
				}
			})
		}
	}
}
</script>